import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import AppTheme from './pages/shared-theme/AppTheme';
import { useNavigate } from 'react-router-dom';
import { RaffleteIconBig } from "./pages/shared-theme/CustomIcons";

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: 'auto',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '450px',
  },
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  ...theme.applyStyles('dark', {
    boxShadow:
      'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
  }),
}));

const AppContainer = styled(Box)(({ theme }) => ({
  padding: 20,
  marginTop: '10vh',
  textAlign: 'center',
  '&::before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    zIndex: -1,
    inset: 0,
    backgroundImage:
      'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
    backgroundRepeat: 'no-repeat',
    ...theme.applyStyles('dark', {
      backgroundImage:
        'radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))',
    }),
  },
}));

export default function App() {
  const navigate = useNavigate();

  const handleSignUpClick = () => {
    navigate('/signin');
  };

  return (
    <AppTheme>
      <CssBaseline enableColorScheme />
      <AppContainer>
        <a href="/">
          <RaffleteIconBig />
        </a>

        {/* Centered Content */}
        <Card variant="outlined">
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mb: 2,
              gap: 2,
            }}
          >
            <Typography
              component="h2"
              variant="h5"
              sx={{
                fontSize: 'clamp(1rem, 5vw, 1.2rem)',
                fontWeight: 600,
                whiteSpace: 'nowrap',
                fontFamily: 'Helvetica',
              }}
            >
              WIN BIG, LOSE NONE
            </Typography>
            <Box
              component="img"
              src="/logo.svg"  // Use the correct path to the logo in the public folder
              alt="logo"
              sx={{
                animation: 'spin 2s linear infinite',
                width: 50,
                height: 50,
                '@keyframes spin': {
                  '0%': { transform: 'rotate(0deg)' },
                  '100%': { transform: 'rotate(-360deg)' },
                },
              }}
            />
          </Box>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 2, fontFamily: 'Helvetica, Arial, sans-serif', fontWeight: 'bold' }}
            onClick={handleSignUpClick}
          >
            Sign In
          </Button>
        </Card>
      </AppContainer>
    </AppTheme>
  );
}
