import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import AppTheme from '../shared-theme/AppTheme';
import { useNavigate } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: 'auto',
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  [theme.breakpoints.up('sm')]: {
    width: '450px',
  },
  ...theme.applyStyles('dark', {
    boxShadow:
      'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
  }),
}));

const DashboardContainer = styled(Stack)(({ theme }) => ({
  minHeight: '90vh',
  padding: theme.spacing(4),
  backgroundImage:
    'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
  backgroundRepeat: 'no-repeat',
  ...theme.applyStyles('dark', {
    backgroundImage:
      'radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))',
    justifyContent: 'center',
  }),
}));

export default function Dashboard(props) {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Perform logout actions (e.g., clear user data from localStorage)
    localStorage.removeItem('user');
    navigate('/'); // Redirect to the home page or login page
  };

  return (
    <AppTheme {...props}>
      <CssBaseline enableColorScheme />
      <DashboardContainer direction="column" justifyContent="space-between">
        <Card variant="outlined">
          <Typography
            component="h1"
            variant="h4"
            sx={{ width: '100%', fontSize: 'clamp(1.8rem, 10vw, 1.8rem)' }}
          >
            DASHBOARD
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              alignItems: 'center',
            }}
          >
            <Typography variant="body1" sx={{ textAlign: 'center' }}>
              Welcome to your dashboard! You are now logged in.
            </Typography>
            <Button
              fullWidth
              variant="contained"
              onClick={() => navigate('/profile')} // Navigate to profile page
            >
              Go to Profile
            </Button>
            <Button
              fullWidth
              variant="outlined"
              onClick={handleLogout} // Handle logout
            >
              Logout
            </Button>
          </Box>
        </Card>
      </DashboardContainer>
    </AppTheme>
  );
}